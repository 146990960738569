import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { st, classes } from './EmptyState.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type SchedulingEmptyStateProps = {
  message: string;
  dataHook?: string;
};
export const EmptyState: React.FC<SchedulingEmptyStateProps> = ({
  message,
  dataHook,
}) => {
  const { isRTL, isMobile } = useEnvironment();
  return (
    <Text
      className={st(classes.text, { isRTL, isMobile })}
      data-hook={dataHook || 'status-message'}
    >
      {message}
    </Text>
  );
};
