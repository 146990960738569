import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { st, classes } from './DailySessions.st.css';
import { DayDetails } from '../DayDetails/DayDetails';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import Divider from '../Divider/Divider';
import { SchedulingDayViewModel } from '../../../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import settingsParams from '../../../settingsParams';
import { useLoadAllSessions } from '../useLoadAllSessions';

export interface DailySessionsProps {
  viewModel: SchedulingDayViewModel;
}

export const DailySessions: React.FC<DailySessionsProps> = ({ viewModel }) => {
  const settings = useSettings();
  const { date, dailySessions } = viewModel;
  const { isRTL } = useEnvironment();
  const totalNumberOfSessions = dailySessions.length;
  const { numberOfVisibleSessions, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleSessionLimit,
    totalNumberOfSessions,
  });

  const showDivider = (index) => {
    const lastElementIndex =
      (settings.get(settingsParams.showSessionLimit)
        ? Math.min(numberOfVisibleSessions, totalNumberOfSessions)
        : totalNumberOfSessions) - 1;
    return index !== lastElementIndex ? <Divider /> : null;
  };

  const sessionsToShow = settings.get(settingsParams.showSessionLimit)
    ? dailySessions.slice(0, numberOfVisibleSessions)
    : dailySessions;

  return (
    <li className={st(classes.root, { isRTL })} data-hook="daily-sessions">
      <div className={classes.day} data-hook="day-details-wrapper">
        <DayDetails date={date} />
      </div>
      <div className={classes.sessionsWrapper}>
        <ul
          aria-relevant="additions"
          className={classes.sessions}
          data-hook="sessions-wrapper"
        >
          {sessionsToShow.map((sessionDetails, index) => {
            return (
              <li data-hook="session-details" key={index}>
                <SessionDetails {...sessionDetails} />
                {showDivider(index)}
              </li>
            );
          })}
        </ul>
        {settings.get(settingsParams.showSessionLimit) && <LoadAllSessions />}
      </div>
    </li>
  );
};
