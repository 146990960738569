import React from 'react';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { classes } from './Loader.st.css';

export const Loader = () => {
  return (
    <div className={classes.root} data-hook="scheduling-loader">
      <SpinnerWrapper diameter={24} />
    </div>
  );
};
